/*
const $ = require('jquery');
window.jQuery = $;
*/

import '../css/style.scss';

/*
$(function() { 

});
*/
